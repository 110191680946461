import React, { PropsWithChildren, useState } from "react"
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import borderImg from "../assets/images/border.png"
import arrowImg from "../assets/images/arrow_bottom.png"
import Expanse from "react-expanse";
let msg = [
    {
        label: "What are Rootners?",
        msg: "Rootners NFTs are the genesis spirits of Rootswap. There are a total of **** Rootners. Owning one of these genesis Rootners unlocks access to mainnet, special features, boosted rewards, exclusive events, participation in governance, and more."
    },
    {
        label: "What is the distribution of The Rootners collection?",
        msg: `The first **** Rootners NFTs will be free to mint.
        **** Rootners NFTs will be reserved for current and future partners
        **** Rootners NFTs will be reserved for the team to provide initial liquidity
        The remaining **** Rootners NFTs can be minted at ****
        `
    },

    {
        label: "What’s Rootswap?",
        msg: `Rootswap is an Automated Market Maker (AMM)-based decentralized exchange for swapping NFTs with VE(3,3) token incentive model and token incentives based on trading volume. To put it simply, Rootswap is Sudoswap on steroids.`
    },
]

export const QAWidget: React.FC<PropsWithChildren> = () => {
    return (
        <QAStyle>

            <h3>FAQS</h3>

            {msg.map((ev) => <ItemWidget {...ev} />)}


        </QAStyle>
    )
}
type ItemType = {
    label: string,
    msg: string
}
const ItemWidget = ({ label, msg }: ItemType) => {
    const [isShow, setShow] = useState(false)
    return (
        <ItemStyle>
            <h4 onClick={() => {
                setShow(!isShow)
            }}>
                <img className='bg' src={borderImg} alt="" />
                <span>{label}</span>
                <img className={`arrow ${isShow && 'show'}`} src={arrowImg} alt="" />
            </h4>
            <div className={'border'} />
            <Expanse show={isShow}>
                <h5>{msg}</h5>
            </Expanse>

        </ItemStyle>
    );
}

const ItemStyle = styled.div`
    margin-top: 30px;
    width: 70%; 
    margin: 20px auto;
    /* margin: 30px auto; */
    h4{

        box-sizing: border-box;
        font-size: 28px;
        position: relative;
        .bg{
            display: block;
            width: 100%;
        }
        .arrow{
            display: block;
            width: 37px;
            height: 21px;
            position: absolute;
            right: 40px;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: all .3s;
            transform: rotate(-90deg);
        }
        .show{
            transform: rotate(0deg);
            
        }
        span{
            position: absolute;
            top: 0;
            bottom:0 ;
            display: flex;

            height: 100%;
            align-items: center;
            padding-left: 30px;
        }
    }
    h5{
        font-size: 18px;
        padding: 0 20px;
        box-sizing: border-box;
        font-family: zx;
        font-weight: 300;
    }

    .border{
        height: 20px;
    }

    @media (max-width: 1000px) {
        width: 80%; 
        .bg{
            height: 40px;
        }
        
       h4{
           font-size: 14px;
           .arrow{
                width: 15px;
                height: 8px;
                right: 10px;
            }
            span{
                padding-left: 10px;
            }
       }
       .border{
            height: 10px;
        }
       h5{
           font-size: 12px;
       }
    }

    @media (max-width: 600px) {
        width: 100%; 
        .bg{
            height: 40px;
        }
        
       h4{
           font-size: 14px;
           .arrow{
                width: 15px;
                height: 8px;
                right: 10px;
            }
            span{
                padding-left: 10px;
            }
       }
       .border{
            height: 10px;
        }
       h5{
           font-size: 12px;
       }
    }
`

const QAStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 60px;
    h3{
        font-size: 85px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 80px;
    }

    @media (max-width: 600px) {

        h3{
            margin-bottom: 40px;
        }
}


    
`