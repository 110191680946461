

import './App.css';
import { BannerWidget } from './components/banner';
import { FooterWidget } from './components/foot';
import { HeaderWidget } from './components/header';
import { MainWidget } from './components/main';
import { QAWidget } from './components/qa';
import { UtilityWidget } from './components/utility';
import { Element } from "react-scroll"
function App() {
  return (
    <div className="App">
      <HeaderWidget />
      <BannerWidget />
      <Element name="test1" className="element" />
      <MainWidget />
      <Element name="test2" className="element" />
      <UtilityWidget />
      <Element name="test3" className="element" />
      <QAWidget />
      <FooterWidget />
    </div>
  );
}

export default App;
