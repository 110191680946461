import React, { PropsWithChildren, ReactElement, ReactNode } from "react"
import styled from "styled-components";
import i1 from "../assets/images/mint.png"
import i2 from "../assets/images/early.png"
import i3 from "../assets/images/boosted.png"
import i4 from "../assets/images/vip.png"
import i5 from "../assets/images/governance.png"
import { Grid } from "@material-ui/core";

let msg = [
    {
        img: i1,
        label: "Mint Cashback",
        msg: "Receive a $ROOT airdrop covering the total cost of all your mints. If you're already entitled to a larger airdrop, a multiplier will be applied."
    },
    {
        img: i2,
        label: "Early Bird Access",
        msg: "Early access to the rootswap mainnet and all future features.Be the first to pioneer and unique NFT AMM trading."
    },
    {
        img: i3,
        label: "Boosted Token Rewards",
        msg: "Receive extra token rewards and incentives for testing the protocol's early features."
    },
    {
        img: i4,
        label: "VIP Community Pass",
        msg: "Access to exclusive events in Discord,metaverse, limited alpha, gaming, education,entertainment, and more."
    },
    {
        img: i5,
        label: "Governance Power",
        msg: "Until the official token is released, all Rootners holders will be able to vote on Rootswap decisions. You decide the future of Rootswap."
    },
]

export const UtilityWidget: React.FC<PropsWithChildren> = () => {
    return (
        <UtilityStyle>
            <h3>UTILITY</h3>
            <Grid container spacing={4} justify={"center"}>
                {
                    msg.map((ev) => <ItemWidget {...ev} />)
                }
            </Grid>


        </UtilityStyle>
    )
}
type ItemType = {
    img: string,
    label: string,
    msg: string
}
const ItemWidget = ({ img, label, msg }: ItemType) => {
    return (
        <Grid item sm={6} md={4} xs={12}>
            <ItemStyle>

                <div className="img_wap">
                    <img src={img} alt="" />
                </div>
                <h4>{label}</h4>
                <h5>{msg}</h5>
            </ItemStyle>
        </Grid>
    );
}

const ItemStyle = styled.div`
text-align: center;
margin-top: 30px;
.img_wap{
    width: 80px;
    height: 80px;
    margin: 0 auto;
    img{
    display: block;
    width: 100%;
    
}
}

h4{
margin: 30px 0 0 0;
font-size: 30px;
}
h5{
    font-size: 14px;
    font-family: zx;
    font-weight: 300;
}
`

const UtilityStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    h3{
        font-size: 85px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 80px;
    }
    @media (max-width: 1000px) {
        h3{
            margin-bottom: 30px;
        }
    }

    @media (max-width: 600px) {
        h3{
            margin-bottom: 30px;
            font-size: 85px;
        }
    }
    
`